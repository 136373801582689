import React, { useEffect, useRef } from "react";

const ResumeViewModal = ({ isOpen, onClose, resumeURL, jobRole, fileType }) => {
  const modalRef = useRef();

  // Close the modal when clicking outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Determine the iframe source URL based on the file type
  const getViewerURL = () => {
    if (fileType === "pdf") {
      return resumeURL; // Directly display PDF
    }
    // Use Microsoft Viewer for other file types
    // return `https://docs.google.com/gview?url=${resumeURL}&embedded=true`;
    return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
      resumeURL
    )}`;
  };

  if (!isOpen) return null;

  return (
    <div className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className="overflow-y-auto p-6 mx-4 w-full max-w-5xl bg-white rounded-lg shadow-lg"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Resume Preview - {jobRole}</h2>
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700 focus:outline-none"
          >
            Close
          </button>
        </div>
        <div className="h-[36rem] overflow-hidden">
          {resumeURL ? (
            <iframe
              src={getViewerURL()}
              title="Resume"
              className="w-full h-full"
              frameBorder="0"
            ></iframe>
          ) : (
            <p>No resume available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeViewModal;
