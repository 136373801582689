import React, { useState, useEffect } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditAssignmentModal from "./EditAssignmentModal";

export default function AssignmentList() {
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null); // For delete confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("pending");

  // Fetch assignments when component mounts
  useEffect(() => {
    fetchAssignments();
  }, []);

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://website-backend.starlabs.co.in/api/assignments"
      );
      const data = await response.json();
      setAssignments(data.assignments || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assignments:", error);
      toast.error("Failed to fetch assignments.");
      setLoading(false);
    }
  };

  const openDeleteModal = (assignment) => {
    setSelectedAssignment(assignment);
    setShowDeleteModal(true);
  };

  const openEditModal = (assignment) => {
    setSelectedAssignment(assignment);
    setEditModalOpen(true);
  };

  const handleDeleteAssignment = async () => {
    try {
      let apiUrl = `https://website-backend.starlabs.co.in/api/assignments/delete`;

      if (selectedAssignment.status === "pending") {
        apiUrl =
          "https://website-backend.starlabs.co.in/api/assignments/delete";
      } else if (selectedAssignment.status === "Shortlisted") {
        apiUrl =
          "https://website-backend.starlabs.co.in/api/assignments/delete/shortlisted";
      }

      const requestBody = {
        email: selectedAssignment.email,
        role: selectedAssignment.appliedFor,
      };

      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        toast.success("Assignment deleted successfully");
        setAssignments(
          assignments.filter((a) => a.id !== selectedAssignment.id)
        );
        setShowDeleteModal(false);
      } else {
        toast.error("Failed to delete assignment");
      }
    } catch (error) {
      toast.error("Error deleting assignment");
    }
  };

  const handleEditAssignmentStatus = async (assignmentId, newStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/assignments/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: assignmentId, status: newStatus }),
        }
      );
      const data = await response.json();
      toast.success(data.message || "Status updated successfully");
      fetchAssignments();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    }
  };

  // Filter assignments based on status
  const filteredAssignments = assignments.filter(
    (a) => a.status === filterStatus
  );

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="mb-4 text-lg font-bold">Assignment List</h2>
      {/* Filter Dropdown */}
      <div className="mb-4">
        <select
          className="w-48 px-2 py-1 pr-6 border border-gray-300 rounded"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="pending">Pending</option>
          <option value="Shortlisted">Shortlisted</option>
        </select>
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <div className="flex space-x-3">
            {/* Loading animation */}
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
            <div className="w-4 h-4 delay-150 bg-blue-400 rounded-full animate-pulse"></div>
            <div className="w-4 h-4 delay-300 bg-blue-400 rounded-full animate-pulse"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 table-auto">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 border-b">Name</th>
                  <th className="px-4 py-2 border-b">Email</th>
                  <th className="px-4 py-2 border-b">Applied For</th>
                  <th className="px-4 py-2 border-b">Link</th>
                  <th className="px-4 py-2 border-b">Status</th>
                  <th className="px-4 py-2 border-b">Created At</th>
                  <th className="px-4 py-2 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredAssignments.length > 0 ? (
                  filteredAssignments.map((assignment) => (
                    <tr key={assignment.id} className="hover:bg-gray-50">
                      <td className="px-4 py-2 break-words border-b">
                        {assignment.name}
                      </td>
                      <td className="px-4 py-2 break-words border-b">
                        {assignment.email}
                      </td>
                      <td className="px-4 py-2 break-words border-b">
                        {assignment.appliedFor}
                      </td>
                      <td className="px-4 py-2 break-words border-b">
                        <a
                          href={assignment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {assignment.link}
                        </a>
                      </td>
                      <td className="px-4 py-2 break-words border-b">
                        {assignment.status}
                      </td>
                      <td className="px-4 py-2 break-words border-b">
                        {assignment.createdAt}
                      </td>
                      <td className="px-4 py-2 text-center border-b">
                        <div className="flex items-center justify-center gap-2">
                          <button
                            onClick={() => openDeleteModal(assignment)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <FaTrash className="text-red-600" />
                          </button>
                          <button
                            onClick={() => openEditModal(assignment)}
                            disabled={assignment.status === "Shortlisted"}
                            className={`text-yellow-600 hover:underline ${
                              assignment.status === "Shortlisted"
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            } `}
                          >
                            <FaEdit />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-4 py-2 text-center border-b">
                      {filterStatus === "Pending"
                        ? "No one is pending"
                        : "No one is shortlisted"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      <EditAssignmentModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        assignment={selectedAssignment}
        onSave={(newStatus) =>
          handleEditAssignmentStatus(selectedAssignment.id, newStatus)
        }
      />

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-bold">Confirm Delete</h3>
            <p>
              Are you sure you want to delete the assignment of{" "}
              {selectedAssignment?.name}?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-3 py-1 mr-2 text-gray-800 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAssignment}
                className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
