import React, { useState, useEffect } from "react";
import Modal from "../Resume/Modal";

const EditAssignmentModal = ({ isOpen, onClose, assignment, onSave }) => {
  const [status, setStatus] = useState("pending");

  // Update status when the modal opens or assignment changes
  useEffect(() => {
    if (assignment && assignment.status) {
      setStatus(assignment.status);
    }
  }, [assignment]);

  const handleSave = () => {
    onSave(status); // Call the onSave callback with the updated status
    onClose(); // Close the modal after saving
  };

  if (!assignment) {
    return null; // Return null if no assignment is passed to avoid errors
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="mb-4 text-lg font-bold">Edit Assignment Status</h2>
      <form>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold">Status</label>
          <div className="flex gap-2">
            {/* Radio buttons for selecting status */}
            <label>
              <input
                type="radio"
                value="pending"
                checked={status === "pending"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Pending
            </label>
            <label>
              <input
                type="radio"
                value="Shortlisted"
                checked={status === "Shortlisted"}
                onChange={(e) => setStatus(e.target.value)}
              />{" "}
              Shortlisted
            </label>
          </div>
        </div>
        {/* Save button */}
        <button
          type="button"
          className="px-4 py-2 text-white bg-blue-500 rounded"
          onClick={handleSave}
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default EditAssignmentModal;
