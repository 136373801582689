import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaInfoCircle, FaEdit, FaTrash } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import ResumeDetailModal from "./ResumeDetailModal";
import EditResumeModal from "./EditResumeModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import ResumeViewModal from "./ResumeViewModal";
import { FaFile } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";

export default function ResumeList() {
  const [selectedJobRole, setSelectedJobRole] = useState("");
  const [resumes, setResumes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("new");
  const [selectedResume, setSelectedResume] = useState(null);
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [pageRange, setPageRange] = useState([1, 10]);
  const [jobRoles, setJobRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isJobDropdownOpen, setIsJobDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [resumeURL, setResumeURL] = useState("");
  const [fileType, setFileType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const resultsPerPage = 10;

  const toggleJobDropdown = () => {
    setIsJobDropdownOpen(!isJobDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsJobDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    fetchJobRoles();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      fetchSearchResults();
    } else {
      fetchResumes(currentPage, filterStatus);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!searchQuery) {
      fetchResumes(currentPage, filterStatus);
    }
  }, [currentPage]);

  useEffect(() => {
    setSearchInput("");
    setSearchQuery("");
    fetchResumes(currentPage, filterStatus);
  }, [filterStatus, selectedRoles]);

  const fetchJobRoles = async () => {
    try {
      const response = await fetch(
        "https://website-backend.starlabs.co.in/api/jobs/roles"
      );
      const data = await response.json();
      setJobRoles(data);
    } catch (error) {
      console.error("Error fetching job roles:", error);
      toast.error("Failed to fetch job roles.");
    }
  };

  const handleRoleSelection = (role) => {
    setSelectedRoles((prevSelected) => {
      if (role === "Others") {
        // Toggle "others"
        if (prevSelected.includes("others")) {
          return prevSelected.filter((r) => r !== "others");
        } else {
          return [...prevSelected, "others"];
        }
      } else {
        return prevSelected.includes(role)
          ? prevSelected.filter((r) => r !== role)
          : [...prevSelected, role];
      }
    });
  };

  const fetchResumes = async (page, status) => {
    setLoading(true);
    try {
      const rolesQuery =
        selectedRoles.length > 0
          ? `&role=[${selectedRoles.join(", ")}]`
          : `&role=[]`;
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/resumes?status=${status}${rolesQuery}&page=${page}&limit=10`
      );
      const data = await response.json();
      setResumes(data.resumeDetails || []);
      setTotalPages(data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching resumes:", error);
      toast.error("Failed to fetch resumes.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (resumeId) => {
    console.log(resumeId, "kabdhadhjasdja");
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/resumes`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: resumeId }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message || "Resume deleted successfully");
        fetchResumes(currentPage, filterStatus);
        setDeleteModalOpen(false);
      } else {
        toast.error(data.message || "Failed to delete resume.");
      }
    } catch (error) {
      console.error("Error deleting resume:", error);
      toast.error("Failed to delete resume.");
    }
  };

  const handleViewResumeDetail = async (resumeId) => {
    console.log(resumeId, "kabdhadhjasdja");
    try {
      const response = await fetch(
        `https://website-backend.starlabs.co.in/api/resumes/${resumeId}`
      );
      const data = await response.json();
      if (response.ok) {
        setSelectedResume(data);
        setDetailModalOpen(true);
      } else {
        toast.error(data.message || "Failed to fetch resume detail.");
      }
    } catch (error) {
      console.error("Error fetching resume detail:", error);
      toast.error("Failed to fetch resume detail.");
    }
  };

  const handleViewResume = (resumeURL, jobRole, fileType) => {
    setResumeURL(resumeURL);
    setFileType(fileType);
    setSelectedJobRole(jobRole); // New state to hold the job role
    setViewModalOpen(true);
  };

  const handleEditResumeStatus = async (resumeId, newStatus) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/resumes`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: resumeId, status: newStatus }),
      });
      const data = await response.json();
      toast.success(data.message || "Status updated successfully");
      await new Promise((resolve) => {
        setLoading(true);
        setTimeout(resolve, 1000);
      });
      fetchResumes(currentPage, filterStatus);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    }
  };

  const groupResumesByDate = (resumes) => {
    return resumes.reduce((acc, resume) => {
      const date = new Date(resume.createdAt).toLocaleDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(resume);
      return acc;
    }, {});
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= pageRange[0] && pageNumber <= pageRange[1]) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRangeNext = () => {
    const [start, end] = pageRange;
    if (end < totalPages) {
      setPageRange([start + 10, end + 10]);
    }
  };

  const handleRangePrev = () => {
    const [start, end] = pageRange;
    if (start > 1) {
      setPageRange([start - 10, end - 10]);
    }
  };

  const fetchSearchResults = async () => {
    setLoading(true);
    if (!searchQuery) {
      fetchResumes(currentPage, filterStatus);
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/resumes/search?name=${searchQuery}`
      );
      if (response.ok) {
        const data = await response.json();
        setResumes(data.candidates);
        setTotalPages(Math.ceil(data.candidates.length / resultsPerPage));
      } else {
        setResumes([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      toast.error("Failed to fetch search results.");
    } finally {
      setLoading(false);
    }
  };

  const paginatedResumes = searchQuery
    ? resumes.slice(
        (currentPage - 1) * resultsPerPage,
        currentPage * resultsPerPage
      )
    : resumes;

  const handleSearch = () => {
    if (searchInput.trim() === "") return;
    setSearchQuery(searchInput);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="mb-4 text-lg font-bold">Resume List</h2>

      <div className="flex justify-between items-center mb-4 space-x-4">
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className={`border border-gray-300 rounded px-2 py-1 pr-6 w-48 ${
            filterStatus === "new"
              ? "text-blue-600"
              : filterStatus === "hold"
              ? "text-yellow-600"
              : "text-green-600"
          }`}
        >
          <option value="new">New</option>
          <option value="hold">Hold</option>
          <option value="shortlisted">Shortlisted</option>
          <option value="sent">Sent</option>
        </select>
        <div className="flex items-center px-2 py-1 w-1/2 rounded border border-gray-300">
          <input
            type="text"
            placeholder="Search resumes..."
            className={`w-full border-0 focus:outline-none`}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              if (searchInput.trim() === "") {
                fetchResumes(currentPage, filterStatus);
                setSearchQuery("");
              }
            }}
          />
          <button
            onClick={handleSearch}
            className="ml-2 text-black/50 hover:text-black"
          >
            <FaSearch />
          </button>
        </div>

        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleJobDropdown}
            className="px-2 py-1 pr-6 w-48 rounded border border-gray-300"
          >
            Select Roles
          </button>
          {isJobDropdownOpen && (
            <div className="overflow-y-auto absolute z-10 mt-1 w-64 max-h-60 bg-white rounded border shadow-lg">
              {jobRoles.map((role) => (
                <label key={role} className="block px-4 py-2">
                  <input
                    type="checkbox"
                    checked={selectedRoles.includes(role)}
                    onChange={() => handleRoleSelection(role)}
                    className="mr-2"
                  />
                  {role}
                </label>
              ))}
              <label className="block px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedRoles.includes("others")}
                  onChange={() => handleRoleSelection("Others")}
                  className="mr-2"
                />
                Others
              </label>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="flex space-x-3">
            {/* Loading animation */}
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full delay-150 animate-pulse"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full delay-300 animate-pulse"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 table-auto">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 border-b">Name</th>
                  <th className="px-4 py-2 border-b">Email</th>
                  <th className="px-4 py-2 border-b">Phone</th>
                  <th className="px-4 py-2 border-b">Role</th>
                  <th className="px-4 py-2 border-b">Resume</th>
                  <th className="px-4 py-2 border-b">Date</th>
                  <th className="px-4 py-2 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupResumesByDate(paginatedResumes)).map(
                  ([date, resumes]) => (
                    <React.Fragment key={date}>
                      <tr className="bg-gray-200">
                        <td
                          colSpan="7"
                          className="px-4 py-2 font-bold text-center"
                        >
                          {date}
                        </td>
                      </tr>
                      {resumes.map((resume) => (
                        <tr key={resume.id}>
                          <td className="px-4 py-2 border-b">{resume.name}</td>
                          <td className="px-4 py-2 border-b">{resume.email}</td>
                          <td className="px-4 py-2 border-b">{resume.phone}</td>
                          <td className="px-4 py-2 border-b">{resume.role}</td>
                          <td className="px-4 py-2 border-b">
                            <button
                              onClick={() =>
                                handleViewResume(
                                  resume.resumeURL,
                                  resume.role,
                                  resume.fileType
                                )
                              }
                              className="text-blue-500 hover:underline"
                            >
                              <FaFile />
                            </button>
                          </td>
                          <td className="px-4 py-2 border-b">
                            {new Date(resume.createdAt).toLocaleDateString()}
                          </td>
                          <td className="px-4 py-2 border-b">
                            <button
                              onClick={() => {
                                setSelectedResume(resume);
                                setEditModalOpen(true);
                              }}
                              className="text-yellow-600 hover:underline"
                            >
                              <FaEdit />
                            </button>
                            <button
                              onClick={() => {
                                setSelectedResume(resume);
                                setDeleteModalOpen(true);
                              }}
                              className="ml-2 text-red-600 hover:underline"
                            >
                              <FaTrash />
                            </button>
                            <button
                              onClick={() => handleViewResumeDetail(resume.id)}
                              className="ml-2 text-blue-600 hover:underline"
                            >
                              <FaInfoCircle />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )
                )}
              </tbody>
            </table>
            <div className="flex justify-center items-center mt-4">
              <div className="w-96">
                <div className="flex justify-between items-center mb-2">
                  <button
                    onClick={handleRangePrev}
                    disabled={pageRange[0] === 1}
                    className="px-3 py-1 rounded border border-gray-300"
                  >
                    Prev
                  </button>
                  {Array.from(
                    { length: pageRange[1] - pageRange[0] + 1 },
                    (_, index) => {
                      const pageNumber = pageRange[0] + index;
                      const isActive = pageNumber === currentPage;
                      const isFaded = pageNumber > totalPages;

                      return (
                        <button
                          key={pageNumber}
                          onClick={() => handlePageClick(pageNumber)}
                          className={`border border-gray-300 rounded px-3 py-1 mx-1 ${
                            isActive
                              ? "text-white bg-blue-500"
                              : isFaded
                              ? "text-gray-400"
                              : "hover:bg-blue-500 hover:text-white"
                          }`}
                          style={{ opacity: isFaded ? 0.5 : 1 }}
                          disabled={isFaded}
                        >
                          {pageNumber}
                        </button>
                      );
                    }
                  )}
                  <button
                    onClick={handleRangeNext}
                    disabled={pageRange[1] >= totalPages}
                    className="px-3 py-1 rounded border border-gray-300"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
      <ResumeDetailModal
        isOpen={isDetailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        resume={selectedResume}
      />
      <EditResumeModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        resume={selectedResume}
        onSave={(newStatus) =>
          handleEditResumeStatus(selectedResume.id, newStatus)
        }
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDelete(selectedResume.id)}
      />
      <ResumeViewModal
        isOpen={isViewModalOpen}
        onClose={() => setViewModalOpen(false)}
        resumeURL={resumeURL}
        fileType={fileType}
        jobRole={selectedJobRole}
      />
    </div>
  );
}
